import { render, staticRenderFns } from "./materiais.vue?vue&type=template&id=428b6c1e&scoped=true&"
import script from "./materiais.vue?vue&type=script&lang=js&"
export * from "./materiais.vue?vue&type=script&lang=js&"
import style0 from "./materiais.vue?vue&type=style&index=0&id=428b6c1e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428b6c1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})
